import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import $ from 'jquery';
import Foundation from 'foundation-sites';
import {push} from 'react-router-redux';
import {myConcernItems} from '../appContent/SDSContent';
import {setMyConcerns} from '../../reducers/assessment';
import * as _ from 'lodash-es';
import {myConcernsResultPath} from '../Routes';
import {submitMyConcerns} from '../services/assessment';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const generateRandom = (min, max) => {
    const num = Math.floor(Math.random() * (max - min + 1)) + min;
    return num === 50 ? generateRandom(min, max) : num;
};

class SetMyConcerns extends Component {
    state = {
        index: 0,
        sliderValue: 50,
        myConcerns: []
    };

    componentDidMount() {
        $('.slider').on('moved.zf.slider', () => {
            const sliderValue = parseInt($('.slider-handle').attr('aria-valuenow'), 0);

            this.setState({sliderValue});
        });
    }

    showAnswer = () => {
        const {index, myConcerns} = this.state;
        const answer = myConcerns[index * 2];
        const mySlider = new Foundation.Slider($('.slider'));

        mySlider._setHandlePos($('.slider-handle'),
            100 - (answer?.value || 50));
    };

    nextAnswer = next => {
        const {index, myConcerns, sliderValue} = this.state;

        if (next) {
            const currentItem = myConcernItems[index];

            const newIndex = index * 2;
            myConcerns[newIndex] = {
                actionPlan: currentItem.one,
                value: 100 - sliderValue
            };
            myConcerns[newIndex + 1] = {
                actionPlan: currentItem.two,
                value: sliderValue
            };
        }

        this.setState({
            index: next ? index + 1 : index - 1,
            myConcerns,
            sliderValue: 50
        }, () => this.showAnswer());
    };

    submitAnswers = () => {
        const {dispatch} = this.props;
        const {myConcerns} = this.state;

        const result = _.map(_.groupBy(myConcerns, 'actionPlan.name'), (objs, key) => ({
            name: key, actionPlan: objs[0].actionPlan, value: _.sumBy(objs, 'value')
        })).sort((a, b) => b.value - a.value);

        submitMyConcerns(result.map(value => ({category: value.name.toLowerCase(), score: value.value})));

        dispatch(setMyConcerns(result));
        dispatch(push(`/${i18n.locale}/${myConcernsResultPath}`));
    };

    render() {
        const {index, sliderValue} = this.state;
        const currentValue = index;
        const total = myConcernItems.length;
        const progress = currentValue / total * 100;
        const isLast = currentValue + 1 >= total;
        const currentItem = myConcernItems[index];

        return (
            <div className='grid-x align-center'>
                <div className='cell'>
                    <div className='grid-x my-concerns-banner align-center-middle'>
                        <h3 className='separator-center'>{i18n.t('application.concerns.header')}</h3>
                    </div>
                    <div
                        aria-valuemax={total}
                        aria-valuemin='0'
                        aria-valuenow={currentValue}
                        className='progress'
                        role='progressbar'>
                        <div className='progress-meter' style={{width: `${progress}%`}}/>
                    </div>
                </div>
                <div className='cell medium-9 small-10 text-center padding-vertical-2'>
                    <h6>{i18n.t('application.concerns.sections.text.pair', {currentValue: currentValue + 1, total: total})}</h6>
                    <p dangerouslySetInnerHTML={{__html: i18n.t('application.concerns.sections.text.slider_short')}}/>
                    <AudioFile path={`set_my_concerns/${currentValue + 1}.mp3`}/>
                    <div className='grid-x margin-vertical-2 align-center'>
                        <div className='cell large-8'>
                            <div className='grid-x align-spaced small-up-2'>
                                <div className='cell h5 text-left'><b>
                                    <i className={`fa fa-${currentItem.one.icon} 2x primary-color margin-right-1`}/>
                                    {currentItem.one.title}
                                </b></div>
                                <div className='cell h5 text-right'><b>
                                    <i className={`fa fa-${currentItem.two.icon} 2x primary-color margin-right-1`}/>
                                    {currentItem.two.title}
                                </b></div>
                            </div>
                            <div className='slider' data-initial-start={sliderValue} data-slider>
                                <span
                                    className='slider-handle rounded' data-slider-handle role='slider' tabIndex='1'/>
                                <span className='slider-fill' data-slider-fill/>
                                <input className='slider-input' type='hidden'/>
                            </div>
                            <div className='grid-x align-justify'>
                                <h5><b>{100 - sliderValue}%</b></h5>
                                <h5><b>{sliderValue}%</b></h5>
                            </div>
                        </div>
                    </div>
                    <div className='grid-x align-center'>
                        <div className='cell large-8'>
                            <div className='grid-x align-justify'>
                                <div className='cell shrink'>
                                    <button
                                        className='button hollow shadow slim'
                                        dangerouslySetInnerHTML={{__html: i18n.t('application.concerns.sections.text.buttons.back')}}
                                        disabled={index === 0}
                                        onClick={() => this.nextAnswer(false)}
                                        type='button'
                                    />
                                </div>
                                <div className='cell shrink'>
                                    <button
                                        className='button primary shadow slim'
                                        onClick={() => isLast ? this.submitAnswers() : this.nextAnswer(true)}
                                        type='button'
                                    >
                                        {isLast ? i18n.t('application.concerns.sections.text.buttons.finish') : i18n.t('application.concerns.sections.text.buttons.next_pair')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(SetMyConcerns);

SetMyConcerns.propTypes = {
    dispatch: PropTypes.func.isRequired
};