import React, {Component} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import {I18n} from 'i18n-js';
import translations from './../locales.json';
import AudioFile from './AudioFile';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

export default class InstructionsModal extends Component {
    closeInstructionsModal() {
        $('#instructions-modal .inner-content').scrollTop(0);
        $('#instructions-modal').hide(true);
        Array.from(document.getElementsByTagName('audio')).forEach(el => {
            el.pause();
            el.currentTime = 0;
        });
    }

    render() {
        const {
            hideModals
        } = this.props;

        return (
            <div className='instructions-modal modal' id='instructions-modal'>
                <div className='modal-background' onClick={() => hideModals()}/>
                <div className='modal-content small-10'>
                    <div className='inner-content'>
                        <div className='grid-x align-center text-center'>
                            <div className='cell large-8 small-10 padding-vertical-2'>
                                <h3 className='separator-center margin-bottom-1' dangerouslySetInnerHTML={{__html: i18n.t('application.instructions.header1')}}/>
                                <AudioFile path={`/${i18n.locale}/home/quick_exit_and_return.mp3`}/>
                                <p className='margin-0' dangerouslySetInnerHTML={{__html: i18n.t('application.instructions.paragraph1')}}/>
                            </div>
                            <div className='cell'>
                                <div className='grid-x'>
                                    <div className='exit-container cell medium-5 small-12 padding-horizontal-2 padding-vertical-1'>
                                        <h4 dangerouslySetInnerHTML={{__html: i18n.t('application.instructions.quick_exit')}}/>
                                        <p className='font-bold margin-0' dangerouslySetInnerHTML={{__html: i18n.t('application.instructions.option1')}}/>
                                        <div className='exit-button'>
                                            {i18n.t('application.layouts.exit_button')}
                                        </div>
                                        <div className='desktop-instructions'>
                                            <p className='font-bold divider' dangerouslySetInnerHTML={{__html: i18n.t('application.instructions.or')}}/>
                                            <p className='font-bold' dangerouslySetInnerHTML={{__html: i18n.t('application.instructions.option2')}}/>
                                            <img src='/images/click-gray-background.png'/>
                                        </div>
                                    </div>
                                    <div className='return-container cell medium-7 small-12 padding-horizontal-2 padding-vertical-1'>
                                        <h4 dangerouslySetInnerHTML={{__html: i18n.t('application.instructions.return')}}/>
                                        <p dangerouslySetInnerHTML={{__html: i18n.t('application.instructions.return2')}}/>
                                        <p className='font-bold margin-0' dangerouslySetInnerHTML={{__html: i18n.t('application.instructions.return3')}}/>
                                        <img className='margin-bottom-1' src='/images/display-app.png'/>
                                        <img className='margin-bottom-1' src='/images/display-app-button.svg'/>
                                        <div className='desktop-instructions'>
                                            <p className='font-bold divider' dangerouslySetInnerHTML={{__html: i18n.t('application.instructions.or')}}/>
                                            <p className='font-bold' dangerouslySetInnerHTML={{__html: i18n.t('application.instructions.return4')}}/>
                                            <img src='/images/hotkey.svg'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='grid-x grid-padding-x align-center-middle text-center margin-bottom-2'>
                                    <div className='cell medium-auto small-8 padding-top-2'>
                                        <a className='button shadow' dangerouslySetInnerHTML={{__html: i18n.t('application.instructions.footer_link')}} onClick={() => this.closeInstructionsModal()}/>
                                    </div>
                                    <div className='cell medium-auto small-8 padding-top-2'>
                                        <button
                                            className='button hollow shadow primary-text'
                                            onClick={() => hideModals()}
                                            type='button'
                                        >
                                            <div className='grid-x align-middle'>
                                                <div className='cell shrink margin-right-1'>
                                                    <img src='/images/logo.svg'/>
                                                </div>
                                                <div className='cell auto' dangerouslySetInnerHTML={{__html: i18n.t('application.instructions.button')}}/>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        className='expanded button margin-0 exit'
                        onClick={() => hideModals()}
                        type='button'
                    >{i18n.t('application.layouts.exit_button')}</button>
                </div>
                <button
                    className='expanded button margin-0 exit small-10 outside-exit'
                    onClick={() => hideModals()}
                    type='button'
                >{i18n.t('application.layouts.exit_button')}</button>
            </div>
        );
    }
}

InstructionsModal.propTypes = {
    hideModals: PropTypes.func.isRequired
};