import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {assessmentResult, extremeDangerScore} from '../appContent/AssessmentResult';
import {myConcernsPath} from '../Routes';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {Link} from 'react-router-dom';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const AssessmentResult = ({assessmentData: {totalPoints}}) => {
    const result = assessmentResult(totalPoints);
    return (
        <div className='grid-x align-center'>
            <div className='cell'>
                <div className='grid-x assessment-banner align-center-middle'>
                    <h3 className='separator-center' dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.header')}}/>
                </div>
            </div>
            <div className='cell padding-vertical-2 medium-10 small-10'>
                {result.topDescription}
                {
                    totalPoints < extremeDangerScore &&
                    <div className='grid-x align-center-middle margin-top-2 margin-bottom-2'>
                        <div className='cell large-5 small-10'>
                            <Link
                                className='expanded button primary shadow'
                                to={myConcernsPath}>{i18n.t('application.assessment.sections.results.buttons.next')}</Link>
                        </div>
                    </div>
                }
                {result.bottomDescription}
                <p className='font-bold'>
                    <em dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.quiz.bottom_result')}}/>
                </p>
                <AudioFile path='assessment/results/assess_your_danger_bottom_footer.mp3'
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({assessmentData}) => ({
    assessmentData
});

export default connect(mapStateToProps)(AssessmentResult);

AssessmentResult.propTypes = {
    assessmentData: PropTypes.shape({
        answers: PropTypes.array,
        totalQuestions: PropTypes.number,
        yesCount: PropTypes.number,
        totalPoints: PropTypes.number
    }).isRequired,
    dispatch: PropTypes.func.isRequired
};