import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AccordionContent from '../AccordionContent';
import safetyPlanContent, {
    mountsinaiSafetyURL,
    pcawaSafetyURL
} from '../appContent/SafetyPlan';
import {safetyPlanStepsPath} from '../Routes';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {Link} from 'react-router-dom';
import AudioFile from '../AudioFile';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const SafetyPlan = ({location: {hash}, dispatch, safetyPlanStepIds}) => {
    const filteredContent = safetyPlanContent(dispatch).filter(value =>
        value.linkedIds
            ? value.linkedIds.some(r => safetyPlanStepIds.indexOf(r) >= 0)
            : value);
    filteredContent.map(value => {
        if (value.tabs) {
            value.tabs = value.tabs.filter(tab => !tab.linkedId || safetyPlanStepIds.indexOf(tab.linkedId) > -1);
        }

        return value;
    });

    return (
        <div className='grid-x align-center'>
            <div className='cell'>
                <div className='grid-x safety-plan-banner align-center-middle'>
                    <h3 className='separator-center' dangerouslySetInnerHTML={{__html: i18n.t('application.safety.sections.plan.header')}}/>
                </div>
            </div>
            <div className='cell medium-9 small-10 padding-vertical-2'>
                {filteredContent[0] && <div className='text-center margin-bottom-2'>
                    <h6 dangerouslySetInnerHTML={{__html: i18n.t('application.safety.sections.plan.subheader')}}/>
                    <h1>{filteredContent[0].title}</h1>
                </div>}
                <AccordionContent content={filteredContent} hash={hash}/>
                <div className='grid-x align-center-middle margin-top-2'>
                    <div className='cell large-5 small-10'>
                        <Link
                            className='expanded button primary'
                            dangerouslySetInnerHTML={{__html: i18n.t('application.safety.sections.plan.back_button')}}
                            to={safetyPlanStepsPath}
                        />
                    </div>
                </div>
                <div className='margin-top-1'>
                    <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety.sections.plan.list_header')}}/>
                    <ul>
                        {i18n.t('application.safety.sections.plan.list_items', {pcawaSafetyURL: pcawaSafetyURL, mountsinaiSafetyURL: mountsinaiSafetyURL}).map((item, index) =>
                            <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                    </ul>
                    <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety.sections.plan.bottom_info')}}/>
                    <AudioFile path={`/${i18n.locale}/safety_plan_steps/end_text_under_each_tab.mp3`}/>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({assessmentData: {safetyPlanStepIds}}) => ({
    safetyPlanStepIds
});

export default connect(mapStateToProps)(SafetyPlan);

SafetyPlan.propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string
    }).isRequired,
    safetyPlanStepIds: PropTypes.arrayOf(PropTypes.number).isRequired
};