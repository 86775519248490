import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {actionPlanDetailPath} from '../appContent/SDSContent';
import {safetyPlanStepsPath} from '../Routes';
import {Link} from 'react-router-dom';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import AudioFile from '../AudioFile';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

class SafetyTips extends Component {
    render() {
        const {myConcernResult} = this.props;
        const remainingConcerns = myConcernResult.slice(1, myConcernResult.length);

        return (
            <div className='grid-x align-center'>
                <div className='cell'>
                    <div className='grid-x safety-tips-banner align-center-middle'>
                        <h3 className='separator-center' dangerouslySetInnerHTML={{__html: i18n.t('application.safety.sections.tips.my_other_concerns_header')}}/>
                    </div>
                </div>
                <div className='cell medium-9 small-10 text-center padding-vertical-2'>
                    <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety.sections.tips.ranking_concerns')}}/>
                    <AudioFile path={`/${i18n.locale}/safety_tips/safety_tips_after_action_plan.mp3`}/>
                    <div className='margin-top-2 margin-bottom-1'>
                        <ul
                            className='accordion small-11 medium-8 float-center'
                            data-accordion
                            data-allow-all-closed='true'
                            data-deep-link='true'
                            data-deep-link-smudge='true'
                            data-deep-link-smudge-delay='500'
                            data-multi-expand='true'
                            data-update-history='true'
                            id='deeplinked-accordion'
                        >
                            {remainingConcerns.map((value, index) =>
                                <li
                                    className='accordion-item'
                                    data-accordion-item
                                    key={value.name}
                                >
                                    <Link className='accordion-title shadow right-arrow'
                                        to={{pathname: actionPlanDetailPath(value.name), state: {parent: 'safety'}}}
                                    >
                                        <div className='grid-x align-middle margin-left-1' key={value.name}>
                                            <div className='small-1 cell primary-color'>
                                                {index + 1}
                                            </div>
                                            <div className='shrink cell small-2 medium-1 text-center'>
                                                <i
                                                    className={`fa fa-${
                                                        value.actionPlan.icon
                                                    } 2x primary-color`}
                                                />
                                            </div>
                                            <div className='shrink cell'>
                                                <h6 className='my-concern-title'>{value.actionPlan.title}</h6>
                                            </div>
                                        </div>
                                    </Link>
                                </li>)}
                        </ul>
                    </div>

                    <div className='grid-x margin-top-2 align-center-middle'>
                        <div className='cell large-5 small-10'>
                            <Link
                                className='expanded button primary shadow'
                                dangerouslySetInnerHTML={{__html: i18n.t('application.safety.sections.tips.more_button')}}
                                to={safetyPlanStepsPath}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({assessmentData}) => {
    const {myConcernResult} = assessmentData;
    return {myConcernResult};
};

export default connect(mapStateToProps)(SafetyTips);

SafetyTips.propTypes = {
    dispatch: PropTypes.func.isRequired,
    myConcernResult: PropTypes.arrayOf(PropTypes.shape()).isRequired
};