import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {push} from 'react-router-redux';
import {safetyPlanSteps} from '../appContent/SafetyPlan';
import {safetyPlanPath, otherConcernsPath} from '../Routes';
import {setSafetyPlan} from '../../reducers/assessment';
import AudioFile from '../AudioFile';
import ReactGA from 'react-ga4';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {Link} from 'react-router-dom';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const trackSafetyPlanEvent = id => {
    ReactGA.event({
        category: 'Safety Tips',
        action: 'Click',
        label: safetyPlanSteps[id - 1].title
    });
};

class SafetyPlanSteps extends Component {
    state = {
        selected: []
    };

    static getDerivedStateFromProps(props) {
        return {selected: props.safetyPlanStepIds};
    }

    render() {
        const {dispatch} = this.props;
        const {selected} = this.state;
        selected.pop();
        return (
            <div className='grid-x align-center'>
                <div className='cell'>
                    <div className='grid-x safety-tips-banner align-center-middle'>
                        <h3 className='separator-center' dangerouslySetInnerHTML={{__html: i18n.t('application.safety.sections.tips.header')}}/>
                    </div>
                </div>
                <div className='cell medium-9 small-10 text-center padding-vertical-2'>
                    <div className='margin-bottom-2'>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety.sections.tips.increase_safety')}}/>
                        <AudioFile path={`/${i18n.locale}/safety_plan_steps/intro.mp3`}/>
                    </div>
                    {safetyPlanSteps.map(step =>
                        <div
                            className='grid-x align-center-middle margin-top-1'
                            key={step.id}
                        >
                            <div className='cell large-5 small-8'>
                                <a
                                    className={`expanded button ${
                                        selected.indexOf(step.id) > -1 ? 'primary' : 'hollow'
                                    } shadow`}
                                    onClick={() => {
                                        const index = selected.indexOf(step.id);

                                        if (index > -1) {
                                            selected.splice(index, 1);
                                        }
                                        else {
                                            selected.push(step.id);
                                        }

                                        this.setState({selected});
                                        dispatch(setSafetyPlan(selected));
                                        dispatch(push(`/${i18n.locale}/${safetyPlanPath}`));
                                    }}
                                >
                                    {step.label}
                                </a>
                            </div>
                        </div>)
                    }
                    <div className='grid-x align-center-middle margin-top-1'>
                        <div className='cell large-6 small-10'>
                            <Link
                                className='expanded button clear'
                                dangerouslySetInnerHTML={{__html: i18n.t('application.safety.sections.plan.back_button')}}
                                to={otherConcernsPath}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({assessmentData: {safetyPlanStepIds}}) => ({
    safetyPlanStepIds
});

export default connect(mapStateToProps)(SafetyPlanSteps);

SafetyPlanSteps.propTypes = {
    dispatch: PropTypes.func.isRequired,
    safetyPlanStepIds: PropTypes.arrayOf(PropTypes.number).isRequired
};