/* eslint-disable max-len */
import React from 'react';
import {
    actionPlanFinancePath,
    actionPlanHealthPath,
    actionPlanHousingPath,
    actionPlanLegalPath,
    safetyPlanPath,
    actionPlanSocialSupportPath
} from '../Routes';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const entrepreneursURL = 'http://www.women.gov.on.ca/owd/english/economic/entrepreneurs.shtml';
const financialEmpowermentURL = 'https://www.woodgreen.org/services/financial-empowerment';
const schliferclinicURL = 'https://www.schliferclinic.com/legal-services/';
const oasisfemmesURL = 'https://www.oasisfemmes.org/services';
const legalAidURL = 'https://www.legalaid.on.ca';
const legalClinicsURL = 'https://www.legalaid.on.ca/legal-clinics';
const peaceBoundsURL = 'https://www.metrac.org/new-infographic-on-peace-bonds/';
const restrainingOrderURL = 'https://www.metrac.org/tag/restraining-order/';
const refugeeLegalURL = 'http://www.immigrantandrefugeenff.ca/need-help/legal-protection';
const sponsorshipBreakdownURL = 'https://owjn.org/2017/05/sponsorship-breakdown-what-will-happen-to-my-immigration-status/#4';
const northYorkURL = 'https://nywc.org/';
const rexdaleCentreURL = 'http://www.rexdalewomen.org/';
const familyServiceURL = 'https://familyservicetoronto.org/our-services/programs-and-services/violence-against-women/';
const scarboroughCentreURL = 'http://www.scarboroughwomenscentre.ca/counselling/';
const barbraSchliferURL = 'http://schliferclinic.com/services/counselling/';
const abrigoCenreURL = 'http://www.abrigo.ca/services/vaw/';
const workingwomenURL = 'http://www.workingwomencc.org/location/fairview/programs-services/programs-services/' +
    'womens-support-services/';
const findHealthCentreURL = 'https://www.allianceon.org/find-a-centre';
const consentedURL = 'https://humber.ca/student-life/sexual-assault/education';
const shelterSafeURL = 'https://www.sheltersafe.ca/';
const housingConnectionsURL = 'https://www.housingconnections.ca/';
const priorityProgramURL = 'https://www.toronto.ca/community-people/employment-social-support/housing-support/rent-geared' +
    '-to-income-subsidy/survivors-of-domestic-abuse-or-human-trafficking/';
const schliferclinicHousingURL = 'https://211ontario.ca/211-topics/housing/transitional-housing/';
const zoloURL = 'https://www.zolo.ca';
const ywcaURL = 'https://ywcacanada.ca/';
const benefitFinderToolURL = 'https://www.toronto.ca/community-people/employment-social-support/benefit-finder-tool/#0';
const ontarioGovernmentWebPageURL = 'https://www.ontario.ca/page/renting-changes-during-covid-19';
const housingHelpAssociationOfOntarioURL = 'https://hhao.ca/services/';
const covidBenefitsCanadaURL = 'https://covid-benefits.alpha.canada.ca/en/start';
const incomeSecurityAdvocacyCenterURL = 'http://incomesecurity.org/';
const planningNetworkURL = 'http://www.planningnetwork.ca/en-ca/resources/28358/COVID-19-FAQ';
const betterHelpURL = 'https://www.betterhelp.com/';
const headSpaceURL = 'https://www.headspace.com/';
const covidCoachURL = 'https://play.google.com/store/apps/details?id=gov.va.mobilehealth.ncptsd.covid&hl=en';
const bounceBackURL = 'https://bouncebackontario.ca/';
const mentalHealthAssociationURL = 'https://cmhaww.ca/tips-on-how-to-manage-your-mental-health-during-covid-19/';
const appleTreeURL = 'https://appletreemedicalgroup.com/';
const workingWomenCommunityCentreURL = 'http://www.workingwomencc.org/location/fairview/programs-services/programs-services/womens-support-services/';
const covidCommunityResponseNetworksURL = 'https://docs.google.com/document/d/1LsHkTBMS0YX_4F7OSAt55ppV0xICi50AXdF62vRw4W8/edit';
const artAndCultureGoogleURL = 'https://artsandculture.google.com/partner?hl=en';
const artGalleryOfOntarioURL = 'https://ago.ca/collection/browse';
const stepsToJusticeURL = 'https://stepstojustice.ca/legal-topic/covid-19';
const stalkingLogURL = 'http://publications.gov.sk.ca/documents/9/95776-Stalking Log 16-11-1.pdf';
const actionPlanContent = () => ({
    [`/${i18n.locale}/${actionPlanHousingPath}`]: {
        title: i18n.t('app_content.action_plan.sections.housing.title'),
        topMyConcernText: i18n.t('app_content.action_plan.sections.housing.top_concern'),
        topDescription: <div>
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.housing.top_description.paragraph1')}}/>
            <AudioFile path={`/${i18n.locale}/action_plans/housing/intro.mp3`}/>
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.housing.top_description.paragraph2')}}/>
            {
                i18n.locale === 'es' &&
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.housing.top_description.paragraph3')}}/>
            }
            <AudioFile path={`/${i18n.locale}/action_plans/housing/covid_instructions.mp3`}/>
        </div>,
        tabs: [i18n.locale === 'fr' && {
            title: i18n.t('app_content.action_plan.sections.housing_changes.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.housing_changes.body.processes')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.housing_changes.body.visit_website', {ontarioGovernmentWebPageURL: ontarioGovernmentWebPageURL})}}/>
                <AudioFile path={`/${i18n.locale}/action_plans/housing/learn_about_changes_to_your_housing_rights.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.emergency_housing.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emergency_housing.body.shelters')}}/>
                <p className='font-bold margin-bottom-0' dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emergency_housing.body.shelter_safe')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.emergency_housing.body.list_items', {shelterSafeURL: shelterSafeURL}).map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <p className='font-bold margin-bottom-0' dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emergency_housing.body.intake_line')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emergency_housing.body.near_toronto')}}/>
                <AudioFile path={`/${i18n.locale}/action_plans/housing/find_out_about_emergency_housing.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.new_home.title'),
            description: <div>
                {
                    i18n.locale === 'es' && <>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.housing_options')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.new_home.body.toronto_list_items').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.special_program')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.new_home.body.program_list_items').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                    </>
                }
                {
                    i18n.locale === 'en' &&
                    <>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.new_home.body.toronto_list_items').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.emergency_housing.body.list_items', {housingConnectionsURL: housingConnectionsURL, housingHelpAssociationOfOntarioURL: housingHelpAssociationOfOntarioURL}).map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.special_program')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.new_home.body.program_list_items', {priorityProgramURL: priorityProgramURL}).map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.transitional_housing')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.new_home.body.transitional_list_items', {schliferclinicHousingURL: schliferclinicHousingURL}).map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.looking_for_places', {zoloURL: zoloURL})}}/>
                    </>
                }
                {
                    i18n.locale === 'fr' &&
                    <>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.housing_options_for_low_income')}}/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.toronto')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.new_home.body.toronto_list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.ontario')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.new_home.body.ontario_list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                    </>
                }
                <AudioFile path={`/${i18n.locale}/action_plans/housing/find_a_new_home.mp3`}/>
            </div>
        }, i18n.locale === 'es' && {
            title: i18n.t('app_content.action_plan.sections.transitional_housing.title'),
            description: <div>
                <ul>
                    {i18n.t('app_content.action_plan.sections.transitional_housing.body.transitional_list_items').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
            </div>
        }].filter(item => item !== false),
        bottomDescription: <p>
            <b dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.bottom_description')}}/>
        </p>
    },
    [`/${i18n.locale}/${actionPlanFinancePath}`]: {
        title: i18n.t('app_content.action_plan.sections.finances.title'),
        topMyConcernText: '',
        topDescription: <div><p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.finances.top_description.top_of_list')}}/>
            <AudioFile path={`/${i18n.locale}/action_plans/finances/intro.mp3`}/>
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.finances.top_description.covid')}}/>
            <AudioFile path={`/${i18n.locale}/action_plans/finances/covid_instructions.mp3`}/>
        </div>,
        tabs: [i18n.locale === 'es' && {
            title: i18n.t('app_content.action_plan.sections.personal_account.title'),
            description: <div>
                <ul>
                    {i18n.t('app_content.action_plan.sections.personal_account.body.list').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/finances/open_personal_account.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.financial_aid.title'),
            description: <div>
                {
                    i18n.locale === 'es' && <>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_aid.body.more_information')}}/>
                    </>
                }
                {
                    i18n.locale !== 'es' && <>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_aid.body.govt_of_canada', {covidBenefitsCanadaURL: covidBenefitsCanadaURL})}}/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_aid.body.resources')}}/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_aid.body.govt_number')}}/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_aid.body.cra_number')}}/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_aid.body.security_advocacy_centre', {incomeSecurityAdvocacyCenterURL: incomeSecurityAdvocacyCenterURL})}}/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_aid.body.further_information', {planningNetworkURL: planningNetworkURL})}}/>

                    </>
                }
                <AudioFile path={`/${i18n.locale}/action_plans/finances/learn_about_financial_aid_available.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.hide_money.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.hide_money.body.save_money')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.hide_money.body.list_items').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/finances/hide_away_some_money.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.social_services.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.social_services.body.programs')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.social_services.body.programs_list', {benefitFinderToolURL: benefitFinderToolURL}).map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                {
                    i18n.locale === 'fr' &&
                    <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.social_services.body.help_in_ontario')}}/>
                }
                <AudioFile path={`/${i18n.locale}/action_plans/finances/sign_up_for_social_services.mp3`}/>
            </div>
        }, i18n.locale === 'fr' && {
            title: i18n.t('app_content.action_plan.sections.food_bank.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.food_bank.body.information')}}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.financial_literacy.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.places_in_toronto')}}/>
                {
                    i18n.locale === 'fr' &&
                    <>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.financial_literacy.body.places_in_toronto_list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.ontario_services')}}/>
                    </>
                }
                {
                    i18n.locale === 'es' &&
                    <>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.settlement_program')}}/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.employability')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.financial_literacy.body.employability_list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.more_information')}}/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.note')}}/>
                    </>
                }
                {
                    i18n.locale !== 'fr' &&
                    <>
                        {
                            i18n.locale !== 'es' && <>
                                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.ywca')}}/>
                                <ul>
                                    {i18n.t('app_content.action_plan.sections.financial_literacy.body.ywca_list', {ywcaURL: ywcaURL}).map((item, index) =>
                                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                                </ul>
                            </>
                        }
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.financial_empowerment')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.financial_literacy.body.financial_empowerment_list', {financialEmpowermentURL: financialEmpowermentURL}).map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        {
                            i18n.locale !== 'es' && <>
                                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.micro_lending')}}/>
                                <ul>
                                    {i18n.t('app_content.action_plan.sections.financial_literacy.body.micro_lending_list', {entrepreneursURL: entrepreneursURL}).map((item, index) =>
                                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                                </ul>
                            </>
                        }
                    </>
                }

                <AudioFile path={`/${i18n.locale}/action_plans/finances/get_help_with_financial_literacy.mp3`}/>
            </div>
        }].filter(item => item !== false),
        bottomDescription: <div>
            <p><b dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.questions')}}/></p>
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.finances',
                {
                    housingPath: `/${i18n.locale}/${actionPlanHousingPath}`,
                    safetyPlanPath: `/${i18n.locale}/${safetyPlanPath}`
                })}}/>
        </div>
    },
    [`/${i18n.locale}/${actionPlanHealthPath}`]: {
        title: i18n.t('app_content.action_plan.sections.health.title'),
        topMyConcernText: i18n.t('app_content.action_plan.sections.health.top_concern'),
        topDescription: <div>
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.health.body.shelter')}}/>
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.health.body.see_below')}}/>
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.health.body.covid')}}/>
            <AudioFile path={`/${i18n.locale}/action_plans/health/intro.mp3`}/>
        </div>,
        tabs: [{
            title: i18n.t('app_content.action_plan.sections.emotional_health.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emotional_health.body.dealing_with_ex')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.emotional_health.body.dealing_with_ex_list').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                {
                    i18n.locale !== 'es' && <>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emotional_health.body.virtual_sites')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.emotional_health.body.virtual_sites_list', {betterHelpURL: betterHelpURL, headSpaceURL: headSpaceURL, covidCoachURL: covidCoachURL, bounceBackURL: bounceBackURL}).map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emotional_health.body.assaulted_women_helpline', {mentalHealthAssociationURL: mentalHealthAssociationURL})}}/>
                    </>
                }
                <AudioFile path={`/${i18n.locale}/action_plans/health/support_your_emotional_health_during_pandemic.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.community_health_centre.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.community_health_centre.body.healthcare_workers')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.community_health_centre.body.healthcare_workers_list', {findHealthCentreURL: findHealthCentreURL}).map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/health/connect_with_a_community_health_centre.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.family_doctor.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.family_doctor.body.ontarios_efforts')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.family_doctor.body.virtual_list', {appleTreeURL: appleTreeURL}).map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.family_doctor.body.in_emergency')}}/>
                <AudioFile path={`/${i18n.locale}/action_plans/health/talk_to_your_family_doctor.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.sexual_health.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.sexual_health.body.without_consent')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.sexual_health.body.consent_list', {consentedURL: consentedURL}).map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/health/protect_your_sexual_health.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.who_to_call.title'),
            description: <div>
                <p><b dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.who_to_call.body.emergencies')}}/></p>
                <ul>
                    {i18n.t('app_content.action_plan.sections.who_to_call.body.emergencies_list').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/health/learn_who_to_call_for_health_emergencies.mp3`}/>
            </div>
        }, i18n.locale === 'es' && {
            title: i18n.t('app_content.action_plan.sections.Health_regulations.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.Health_regulations.body.description')}}/>
            </div>
        }].filter(item => item !== false),
        bottomDescription: <p>
            <b dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.who_to_call.body.bottom_description')}}/>
        </p>
    },
    [`/${i18n.locale}/${actionPlanSocialSupportPath}`]: {
        title: i18n.t('app_content.action_plan.sections.social_support.title'),
        topMyConcernText: i18n.t('app_content.action_plan.sections.social_support.body.covid'),
        topDescription: <div><p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.social_support.body.top_concern')}}/>
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.social_support.body.support_system')}}/>
            <AudioFile path={`/${i18n.locale}/action_plans/social_support/intro.mp3`}/>
        </div>,
        tabs: [{
            title: i18n.t('app_content.action_plan.sections.stay_connected.title'),
            description: <div>
                {i18n.t('app_content.action_plan.sections.stay_connected.paragraphs', {workingWomenCommunityCentreURL: workingWomenCommunityCentreURL, covidCommunityResponseNetworksURL: covidCommunityResponseNetworksURL, artAndCultureGoogleURL: artAndCultureGoogleURL, artGalleryOfOntarioUR: artGalleryOfOntarioURL}).map((item, index) =>
                    <p dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                <AudioFile path={`/${i18n.locale}/action_plans/social_support/stay_connected_during_pandemic.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.someone_you_trust.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.someone_you_trust.body.support')}}/>
                {
                    i18n.locale !== 'fr' && <ul>
                        {i18n.t('app_content.action_plan.sections.someone_you_trust.body.support_list').map((item, index) =>
                            <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                    </ul>
                }
                <AudioFile path={`/${i18n.locale}/action_plans/social_support/talk_to_someone_you_trust.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.support_group.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.support_group.body.meeting_others')}}/>
                <ul>
                    <li>
                        <div dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.support_group.body.may_find_helpful')}}/>
                        {i18n.locale !== 'es' && i18n.t('app_content.action_plan.sections.support_group.body.places', {northYorkURL: northYorkURL, rexdaleCentreURL: rexdaleCentreURL}).map((item, index) =>
                            <div dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                    </li>
                </ul>
                <ul>
                    {i18n.t('app_content.action_plan.sections.support_group.body.call_or_visit').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/social_support/talk_to_a_peer_or_social_support.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.womens_counselor.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.womens_counselor.body.goals')}}/>
                <ul>
                    <li>
                        <div dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.womens_counselor.body.counselors')}}/>
                        {i18n.t('app_content.action_plan.sections.womens_counselor.body.counselors_list').map((item, index) =>
                            <p dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                    </li>
                </ul>
                <ul>
                    {i18n.t('app_content.action_plan.sections.womens_counselor.body.connect_list').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/social_support/talk_to_a_violence_against_women.mp3`}/>
            </div>
        }, i18n.locale !== 'fr' && {
            title: i18n.t('app_content.action_plan.sections.social_activities.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.social_activities.body.feeling_alone')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.social_activities.body.activities', {workingwomenURL: workingwomenURL}).map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/social_support/try_out_social_activities.mp3`}/>
            </div>
        }, i18n.locale === 'es' && {
            title: i18n.t('app_content.action_plan.sections.emotional_health_during_pandemic.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emotional_health_during_pandemic.body.unsafe_behaviour')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emotional_health_during_pandemic.body.strength')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emotional_health_during_pandemic.body.little_things')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emotional_health_during_pandemic.body.toronto_library')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emotional_health_during_pandemic.body.share_your_feelings')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.emotional_health_during_pandemic.body.contact_list').map((item, index) =>
                        <div dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emotional_health_during_pandemic.body.mental_health')}}/>
                <AudioFile path={`/${i18n.locale}/action_plans/social_support/emotional_health_during_pandemic.mp3`}/>
            </div>
        }, i18n.locale === 'es' && {
            title: i18n.t('app_content.action_plan.sections.child_emotional_health.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.child_emotional_health.body.unsafe_behaviour')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.child_emotional_health.body.recommendations')}}/>
                <AudioFile path={`/${i18n.locale}/action_plans/social_support/child_emotional_health.mp3`}/>
            </div>
        }
        ].filter(item => item !== false),
        bottomDescription: <p>
            <b dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.social_activities.body.bottom_description')}}/>
        </p>
    },
    [`/${i18n.locale}/${actionPlanLegalPath}`]: {
        title: i18n.t('app_content.action_plan.sections.legal.title'),
        topMyConcernText: i18n.t('app_content.action_plan.sections.legal.body.top_concern'),
        topDescription: <div><p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.legal.body.steps')}}/>
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.legal.body.covid')}}/>
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.legal.body.updates', {stepsToJusticeURL: stepsToJusticeURL})}}/>
            <AudioFile path={`/${i18n.locale}/action_plans/legal/intro.mp3`}/>
        </div>,
        tabs: [{
            title: i18n.t('app_content.action_plan.sections.family_court_support.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.family_court_support.body.support_workers')}}/>
                <ul>
                    <li>
                        {i18n.t('app_content.action_plan.sections.family_court_support.body.toronto.caption')}
                        <ul>
                            {i18n.t('app_content.action_plan.sections.family_court_support.body.toronto.program_list', {schliferclinicURL: schliferclinicURL, oasisfemmesURL: oasisfemmesURL}).map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                    </li>
                    <li dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.family_court_support.body.outside_toronto.caption')}}/>
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/legal/sign_up_for_family_court_support_program.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.legal_clinic.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.legal_clinic.body.legal_advice')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.legal_clinic.body.resources_list', {legalAidURL: legalAidURL, legalClinicsURL: legalClinicsURL}).map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/legal/contact_a_community_legal_clinic.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.gather_evidence.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.gather_evidence.body.keep_records')}}/>
                <ul>
                    <li>
                        {i18n.t('app_content.action_plan.sections.gather_evidence.body.records.caption', {stalkingLogURL: stalkingLogURL})}
                        <ul>
                            {i18n.t('app_content.action_plan.sections.gather_evidence.body.records.list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                    </li>
                    <li>
                        {i18n.t('app_content.action_plan.sections.gather_evidence.body.notes.caption')}
                        <ul>
                            {i18n.t('app_content.action_plan.sections.gather_evidence.body.notes.list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                    </li>
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/legal/gather_evidence.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.access_a_lawyer.title'),
            description: <div>
                <div className='font-bold'>{i18n.t('app_content.action_plan.sections.access_a_lawyer.body.free_legal_advice.caption')}</div>
                <ul>
                    <li dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.access_a_lawyer.body.free_legal_advice.description')}}/>
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/legal/free_legal_advice.mp3`}/>

                {
                    i18n.locale !== 'es' && <>
                        <div className='font-bold'>{i18n.t('app_content.action_plan.sections.access_a_lawyer.body.referral_service.caption')}</div>
                        <ul>
                            <li dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.access_a_lawyer.body.referral_service.description')}}/>
                        </ul>
                        <AudioFile path={`/${i18n.locale}/action_plans/legal/use_the_law_society_referral_service.mp3`}/>
                    </>
                }

                <div className='font-bold'>{i18n.t('app_content.action_plan.sections.access_a_lawyer.body.duty_counsels.caption')}</div>
                <ul>
                    <li dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.access_a_lawyer.body.duty_counsels.description')}}/>
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/legal/learn_how_to_access_a_lawyer.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.protection_orders.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.protection_orders.body.limit_contact')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.protection_orders.body.two_types')}}/>
                <ol>
                    {i18n.t('app_content.action_plan.sections.protection_orders.body.types_list').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ol>
                <ul>
                    {i18n.locale !== 'fr' && i18n.t('app_content.action_plan.sections.protection_orders.body.orders_and_bonds_list', {restrainingOrderURL: restrainingOrderURL, peaceBoundsURL: peaceBoundsURL}).map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/legal/filing_for_protection_orders.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.immigration_status.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.immigration_status.body.concerns')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.immigration_status.body.things_to_consider')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.immigration_status.body.consideration_list').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.immigration_status.body.family_campaign', {refugeeLegalURL: refugeeLegalURL})}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.immigration_status.body.more_information', {sponsorshipBreakdownURL: sponsorshipBreakdownURL})}}/>
                <AudioFile path={`/${i18n.locale}/action_plans/legal/address_concerns_about_your_immigration_status.mp3`}/>
            </div>
        }, i18n.locale === 'fr' && {
            title: i18n.t('app_content.action_plan.sections.Reference_guide.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.Reference_guide.body.family_law_and_guide')}}/>
                <AudioFile path={`/${i18n.locale}/action_plans/legal/reference_guide.mp3`}/>
            </div>
        }].filter(item => item !== false),
        bottomDescription: <p>
            <b dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.immigration_status.body.bottom_description')}}/>
        </p>
    }
});

export default actionPlanContent;