import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AccordionContent from '../AccordionContent';
import emergencyPlanContent from '../appContent/EmergencyTips';
import {actionPlanPath} from '../Routes';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {Link} from 'react-router-dom';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const EmergencyTips = ({location: {hash}, dispatch, safetyPlanStepIds}) => {
    const filteredContent = emergencyPlanContent().filter(value =>
        value.linkedIds ? value.linkedIds.some(r => safetyPlanStepIds.indexOf(r) >= 0) : value);
    filteredContent.map(value => {
        if (value.tabs) {
            value.tabs = value.tabs.filter(tab =>
                !tab.linkedId || safetyPlanStepIds.indexOf(tab.linkedId) > -1);
        }

        return value;
    });

    return (
        <div className='grid-x align-center'>
            <div className='cell'>
                <div className='grid-x emergency-tips-banner banner align-center-middle'>
                    <h3 className='separator-center' dangerouslySetInnerHTML={{__html: i18n.t('emergency.sections.tips.header')}}/>
                </div>
            </div>
            <div className='cell medium-9 small-10 padding-vertical-2'>
                <AccordionContent content={filteredContent} hash={hash}/>
                <div className='grid-x align-center-middle margin-top-2'>
                    <div className='cell large-5 small-10'>
                        <Link
                            className='expanded button clear'
                            dangerouslySetInnerHTML={{__html: i18n.t('emergency.sections.tips.back_button')}}
                            to={actionPlanPath}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({assessmentData: {safetyPlanStepIds}}) => ({
    safetyPlanStepIds
});

export default connect(mapStateToProps)(EmergencyTips);

EmergencyTips.propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string
    }).isRequired,
    safetyPlanStepIds: PropTypes.arrayOf(PropTypes.number).isRequired
};