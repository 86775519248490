import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {actionPlanPath, setMyConcernsPath} from '../Routes';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {Link} from 'react-router-dom';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const MyConcernsResult = ({myConcernResult}) =>
    <div className='grid-x align-center'>
        <div className='cell'>
            <div className='grid-x my-concerns-banner align-center-middle'>
                <h3 className='separator-center' dangerouslySetInnerHTML={{__html: i18n.t('application.concerns.sections.result.header')}}/>
            </div>
        </div>
        <div className='cell medium-9 small-10 text-center padding-vertical-2'>
            <div className='margin-bottom-2'>
                <h1 dangerouslySetInnerHTML={{__html: i18n.t('application.concerns.sections.result.concerns.header')}}/>
                <AudioFile path={`/${i18n.locale}/set_my_concerns/results.mp3`}/>
            </div>
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.concerns.sections.result.concerns.ranked')}}/>
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.concerns.sections.result.concerns.action_plan')}}/>
            <div className='margin-top-2 margin-bottom-1'>
                {
                    myConcernResult.map((value, index) =>
                        <div
                            className='grid-x grid-margin-x align-center-middle margin-bottom-2' key={value.name}
                        >
                            <div className='small-1 cell primary-color'>{index + 1}</div>
                            <div className='small-1 cell'>
                                <i className={`fa fa-${value.actionPlan.icon} 2x primary-color`}/>
                            </div>
                            <div className='small-7 medium-4 shrink cell'>
                                <h6 className='my-concern-title-2'>{value.actionPlan.title}</h6>
                            </div>
                        </div>)
                }
            </div>
            <div className='grid-x align-center-middle'>
                <div className='cell large-5 small-10'>
                    <Link className='expanded button primary shadow'
                        dangerouslySetInnerHTML={{__html: i18n.t('application.concerns.sections.result.concerns.action_plan_button')}}
                        to={actionPlanPath}
                    />
                    <Link
                        className='expanded button clear'
                        dangerouslySetInnerHTML={{__html: i18n.t('application.concerns.sections.result.concerns.reset_button')}}
                        to={setMyConcernsPath}
                    />
                </div>
            </div>
        </div>
    </div>;

const mapStateToProps = ({assessmentData}) => {
    const {myConcernResult} = assessmentData;
    return {myConcernResult};
};

export default connect(mapStateToProps)(MyConcernsResult);

MyConcernsResult.propTypes = {
    dispatch: PropTypes.func.isRequired,
    myConcernResult: PropTypes.arrayOf(PropTypes.shape()).isRequired
};