import React from 'react';
import PropTypes from 'prop-types';
import {Link, Route} from 'react-router-dom';
import HomeLayout from './AppLayout';
import $ from 'jquery';
import connect from 'react-redux/es/connect/connect';
import {push} from 'react-router-redux';
import {
    actionPlanPath,
    assessmentPath,
    assessmentResultPath, didYouKnowPath,
    emergencyTipsPath,
    actionPlanFinancePath,
    actionPlanHealthPath, homePath,
    actionPlanHousingPath,
    actionPlanLegalPath,
    myConcernsPath,
    myConcernsResultPath,
    safetyPlanPath,
    safetyPlanStepsPath,
    otherConcernsPath,
    setMyConcernsPath,
    actionPlanSocialSupportPath
} from '../Routes';
import LeftMenu from '../LeftMenu';
import ReactGA from 'react-ga4';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

class LayoutWrapper extends React.PureComponent {
    static updateMeta(props) {
        if (props.title.length > 0) {
            document.title = props.title;
            $('meta[name=description]').attr('content', props.description);
            $('meta[property=\'og\\:title\']').attr('content', props.title);
            $('meta[property=\'og\\:description\']').attr('content', props.description);
            $('meta[property=\'og\\:url\']').attr('content', location.href);
        }
    }

    componentDidMount() {
        $(document).on('turbolinks:load', () => setTimeout(this.loadFoundation, 500));
        LayoutWrapper.updateMeta(this.props);
        ReactGA.event('page_view', {page_location: this.props.location.pathname, page_title: this.props.title}); // eslint-disable-line camelcase
    }

    componentDidUpdate() {
        this.loadFoundation();
        this.redirectIfNotAccessible();
        LayoutWrapper.updateMeta(this.props);
        ReactGA.event('page_view', {page_location: this.props.location.pathname, page_title: this.props.title}); // eslint-disable-line camelcase
    }

    loadFoundation = () => {
        $(document).foundation();
        $(document).on('touch tap touchstart', e => {
            if (!$(e.target).hasClass('has-tip') && $('.has-tip').length > 0) {
                $('.has-tip').foundation('hide');
            }
        });
    };

    redirectIfNotAccessible = () => {
        const {dispatch, path, assessmentData} = this.props;
        const isAssessmentDone = assessmentData.answers.length !== 0;
        const isMyConcernsDone = assessmentData.myConcernResult.length !== 0;
        const afterAssessment = [
            `/${i18n.locale}/${myConcernsPath}`,
            `/${i18n.locale}/${setMyConcernsPath}`,
            `/${i18n.locale}/${assessmentResultPath}`
        ];
        const afterMyConcerns = [
            `/${i18n.locale}/${actionPlanPath}`,
            `/${i18n.locale}/${actionPlanFinancePath}`,
            `/${i18n.locale}/${actionPlanHealthPath}`,
            `/${i18n.locale}/${actionPlanHousingPath}`,
            `/${i18n.locale}/${actionPlanLegalPath}`,
            `/${i18n.locale}/${myConcernsResultPath}`,
            `/${i18n.locale}/${safetyPlanPath}`,
            `/${i18n.locale}/${safetyPlanStepsPath}`,
            `/${i18n.locale}/${otherConcernsPath}`,
            `/${i18n.locale}/${actionPlanSocialSupportPath}`
        ];
        const checkAssessment = afterAssessment.indexOf(path) > -1;
        const checkPathMyConcerns = afterMyConcerns.indexOf(path) > -1;

        if (!isAssessmentDone && (checkAssessment || checkPathMyConcerns)) {
            dispatch(push(`/${i18n.locale}/${assessmentPath}`));
        }

        if (!isMyConcernsDone && checkPathMyConcerns) {
            dispatch(push(`/${i18n.locale}/${setMyConcernsPath}`));
        }
    };

    topNavBar = () => {
        const {match, path} = this.props;
        const tabs = [
            {title: i18n.t('application.layouts.topNavBar.tabs.menu'), link: '/not-available', childPaths: []},
            {title: i18n.t('application.layouts.topNavBar.tabs.home'), link: `${match.url}/${homePath}`, childPaths: ['/lock', '/staying-safe-online', '/']},
            {title: i18n.t('application.layouts.topNavBar.tabs.emergency_tips'), link: `${match.url}/${emergencyTipsPath}`, childPaths: []},
            {title: i18n.t('application.layouts.topNavBar.tabs.assessment'), link: `${match.url}/${assessmentPath}`, childPaths: []},
            {title: i18n.t('application.layouts.topNavBar.tabs.didYouKnow'), link: `${match.url}/${didYouKnowPath}`, childPaths: ['/planning', '/support']}];
        let selectedIndex = tabs.findIndex(tab => tab.link === path || tab.childPaths.indexOf(path) > -1);
        selectedIndex = selectedIndex < 0 ? 2 : selectedIndex;
        return (
            <div className='tabs top-menu-tab'>
                {
                    tabs.map((tab, i) =>
                        <li className='tabs-title grid-x align-middle' key={tab.title}>
                            {i === 0
                                ? <a data-open='off-canvas'><i className='fa fa-bars fa-2x'/></a>
                                : <Link aria-selected={selectedIndex === i ? 'true' : 'false'}
                                    to={tab.link}
                                >{i === 1 ? <img src='/images/logo.svg'/> : tab.title}</Link>
                            }
                        </li>)
                }
            </div>
        );
    };

    render() {
        const {
            component: Component,
            layout: Wrapper,
            match,
            title,
            ...rest
        } = this.props;

        return (
            <Route
                {...rest}
                render={matchProps =>
                    <Wrapper
                        title={title}
                        {...matchProps}
                    >
                        <React.Fragment>
                            <div className='off-canvas-wrapper' style={{overflow: 'initial'}}>
                                <LeftMenu match={match}/>
                                <div className='off-canvas-content' data-off-canvas-content>
                                    {this.topNavBar()}
                                    <div className='float-center body-content-container'>
                                        <Component {...matchProps}/>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    </Wrapper>
                }
            />
        );
    }
}

const mapStateToProps = ({assessmentData}) => ({
    assessmentData
});

export default connect(mapStateToProps)(LayoutWrapper);

LayoutWrapper.propTypes = {
    assessmentData: PropTypes.shape({
        answers: PropTypes.array
    }).isRequired,
    component: PropTypes.any,
    dispatch: PropTypes.func.isRequired,
    layout: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

LayoutWrapper.defaultProps = {
    component: null,
    layout: HomeLayout
};