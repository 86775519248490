import React from 'react';
import {Redirect, Switch} from 'react-router-dom';
import LayoutWrapper from './layouts/LayoutWrapper';
import Home from './home/Home';
import Assessment from './assessment/Assessment';
import AssessmentResult from './assessment/AssessmentResult';
import Quiz from './assessment/Quiz';
import SetMyConcerns from './assessment/SetMyConcerns';
import MyConcernsResult from './assessment/MyConcernsResult';
import MyConcerns from './assessment/MyConcerns';
import ActionPlan from './assessment/ActionPlan';
import SafetyPlan from './assessment/SafetyPlan';
import SafetyPlanSteps from './assessment/SafetyPlanSteps';
import DidYouKnow from './didYouKnow/DidYouKnow';
import HideYouTracks from './hideYourTracks/HideYourTracks';
import ActionPlanDetail from './assessment/ActionPlanDetail';
import SafetyTips from './assessment/SafetyTips';
import EmergencyTips from './assessment/EmergencyTips';
import {I18n} from 'i18n-js';
import translations from './../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const actionPlanFinancePath = i18n.t('routes.action_plan_finance');
const actionPlanHealthPath = i18n.t('routes.action_plan_health');
const actionPlanHousingPath = i18n.t('routes.action_plan_housing');
const actionPlanLegalPath = i18n.t('routes.action_plan_legal');
const actionPlanPath = i18n.t('routes.action_plan');
const actionPlanSocialSupportPath = i18n.t('routes.action_plan_social_support');
const assessmentPath = i18n.t('routes.assessment');
const assessmentResultPath = i18n.t('routes.assessment_result');
const didYouKnowPath = i18n.t('routes.did_you_know');
const emergencyTipsPath = i18n.t('routes.emergency_tips');
const hideYourTracksPath = i18n.t('routes.hide_your_tracks');
const homePath = i18n.t('routes.home');
const myConcernsPath = i18n.t('routes.my_concerns');
const myConcernsResultPath = i18n.t('routes.my_concerns_result');
const quizPath = i18n.t('routes.quiz');
const safetyPlanPath = i18n.t('routes.safety_plan');
const safetyPlanStepsPath = i18n.t('routes.safety_plan_steps');
const otherConcernsPath = i18n.t('routes.other_concerns');
const setMyConcernsPath = i18n.t('routes.set_my_concerns');

export {
    actionPlanFinancePath,
    actionPlanHealthPath,
    actionPlanHousingPath,
    actionPlanLegalPath,
    actionPlanPath,
    actionPlanSocialSupportPath,
    assessmentPath,
    assessmentResultPath,
    didYouKnowPath,
    emergencyTipsPath,
    hideYourTracksPath,
    homePath,
    myConcernsPath,
    myConcernsResultPath,
    quizPath,
    safetyPlanPath,
    safetyPlanStepsPath,
    otherConcernsPath,
    setMyConcernsPath
};

const Routes = ({match, location}) => <Switch location={location}>
    <LayoutWrapper
        component={Home}
        description={i18n.t('screens.home.description')}
        exact
        match={match}
        path={`${match.url}/${homePath}`}
        title={i18n.t('screens.home.title')}
    />
    <LayoutWrapper
        component={Assessment}
        description={i18n.t('screens.assessment.description')}
        exact
        match={match}
        path={`${match.url}/${assessmentPath}`}
        title={i18n.t('screens.assessment.title')}
    />
    <LayoutWrapper
        component={Quiz}
        description={i18n.t('screens.quiz.description')}
        exact
        match={match}
        path={`${match.url}/${quizPath}`}
        title={i18n.t('screens.quiz.title')}
    />
    <LayoutWrapper
        component={AssessmentResult}
        description={i18n.t('screens.assessment_result.description')}
        exact
        match={match}
        path={`${match.url}/${assessmentResultPath}`}
        title={i18n.t('screens.assessment_result.title')}
    />
    <LayoutWrapper
        component={MyConcerns}
        description={i18n.t('screens.my_concerns.description')}
        exact
        match={match}
        path={`${match.url}/${myConcernsPath}`}
        title={i18n.t('screens.my_concerns.title')}
    />
    <LayoutWrapper
        component={SetMyConcerns}
        description={i18n.t('screens.set_my_concerns.description')}
        exact
        match={match}
        path={`${match.url}/${setMyConcernsPath}`}
        title={i18n.t('screens.set_my_concerns.title')}
    />
    <LayoutWrapper
        component={MyConcernsResult}
        description={i18n.t('screens.my_concerns_result.description')}
        exact
        match={match}
        path={`${match.url}/${myConcernsResultPath}`}
        title={i18n.t('screens.my_concerns_result.title')}
    />
    <LayoutWrapper
        component={ActionPlan}
        description={i18n.t('screens.action_plan.description')}
        exact
        match={match}
        path={`${match.url}/${actionPlanPath}`}
        title={i18n.t('screens.action_plan.title')}
    />
    <LayoutWrapper
        component={ActionPlanDetail}
        description={i18n.t('screens.action_plan_legal.description')}
        exact
        match={match}
        path={`${match.url}/${actionPlanLegalPath}`}
        title={i18n.t('screens.action_plan_legal.title')}
    />
    <LayoutWrapper
        component={ActionPlanDetail}
        description={i18n.t('screens.action_plan_social_support.description')}
        exact
        match={match}
        path={`${match.url}/${actionPlanSocialSupportPath}`}
        title={i18n.t('screens.action_plan_social_support.title')}
    />
    <LayoutWrapper
        component={ActionPlanDetail}
        description={i18n.t('screens.action_plan_health.description')}
        exact
        match={match}
        path={`${match.url}/${actionPlanHealthPath}`}
        title={i18n.t('screens.action_plan_health.title')}
    />
    <LayoutWrapper
        component={ActionPlanDetail}
        description={i18n.t('screens.action_plan_finance.description')}
        exact
        match={match}
        path={`${match.url}/${actionPlanFinancePath}`}
        title={i18n.t('screens.action_plan_finance.title')}
    />
    <LayoutWrapper
        component={ActionPlanDetail}
        description={i18n.t('screens.action_plan_housing.description')}
        exact
        match={match}
        path={`${match.url}/${actionPlanHousingPath}`}
        title={i18n.t('screens.action_plan_housing.title')}
    />
    <LayoutWrapper
        component={SafetyPlanSteps}
        description={i18n.t('screens.safety_plan_steps.description')}
        exact
        match={match}
        path={`${match.url}/${safetyPlanStepsPath}`}
        title={i18n.t('screens.safety_plan_steps.title')}
    />
    <LayoutWrapper
        component={SafetyPlan}
        description={i18n.t('screens.safety_plan.description')}
        exact
        match={match}
        path={`${match.url}/${safetyPlanPath}`}
        title={i18n.t('screens.safety_plan.title')}
    />
    <LayoutWrapper
        component={DidYouKnow}
        description={i18n.t('screens.did_you_know.description')}
        exact
        match={match}
        path={`${match.url}/${didYouKnowPath}`}
        title={i18n.t('screens.did_you_know.title')}
    />
    <LayoutWrapper
        component={HideYouTracks}
        description={i18n.t('screens.hide_your_tracks.description')}
        exact
        match={match}
        path={`${match.url}/${hideYourTracksPath}`}
        title={i18n.t('screens.hide_your_tracks.title')}
    />
    <LayoutWrapper
        component={SafetyTips}
        description={i18n.t('screens.safety_tips.description')}
        exact
        match={match}
        path={`${match.url}/${otherConcernsPath}`}
        title={i18n.t('screens.safety_tips.title')}
    />
    <LayoutWrapper
        component={EmergencyTips}
        description={i18n.t('screens.emergency_tips.description')}
        exact
        match={match}
        path={`${match.url}/${emergencyTipsPath}`}
        title={i18n.t('screens.emergency_tips.title')}
    />
    <Redirect to={`/${document.documentElement.lang}/${homePath}`}/>
</Switch>;

export default Routes;