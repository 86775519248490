import React from 'react';
import DYKContent from '../appContent/DoYouKnow';
import AccordionContent from '../AccordionContent';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const DidYouKnow = () =>
    <div className='grid-x align-center'>
        <div className='cell grid-x did-you-know-banner align-center-middle'>
            <h3 className='separator-center' dangerouslySetInnerHTML={{__html: i18n.t('application.didYouKnow.title')}}/>
        </div>
        <div className='cell medium-10 small-11 padding-vertical-2'>
            <AccordionContent content={DYKContent}/>
        </div>
    </div>;

export default DidYouKnow;