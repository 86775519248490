import React, {Component} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import CallToActionModal from './CallToActionModal';
import {I18n} from 'i18n-js';
import translations from './../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

export default class ModalManager extends Component {
    state = {
        isHidden: false,
        isMainContentActive: false
    };

    componentDidMount() {
        if (document.cookie.split(';').some(c => c.indexOf('hide_content=false') !== -1)) {
            this.setState({isHidden: false});
        }

        document.addEventListener('keypress', e => {
            if (!this.state.isHidden) {
                return;
            }

            if (e.key === 'k') {
                this.showModals();
            }
        });
    }

    componentWillUnmount() {
        document.removeEventListener('keypress');
    }

    changeLanguage = locale => {
        const localeRegex = new RegExp(`^/${i18n.locale}?(/|$)`);
        const localePresent = localeRegex.test(location.pathname);
        const newPathname = localePresent ? location.pathname.replace(localeRegex, `/${locale}/`) : `/${locale}${location.pathname}`;
        location.href = `${location.origin}${newPathname}`;
    };

    handleCallToActionClick = () => {
        this.setState({isMainContentActive: true, isHidden: false});
        $('#instructions-modal').show();
    };

    showModals = () => {
        this.setState({isHidden: false, isMainContentActive: false});
        document.cookie = 'hide_content=false';
        $('#instructions-modal').show();
    };

    hideModals = () => {
        $('#instructions-modal .inner-content, .main-modal-content-container').scrollTop(0);
        this.setState({isHidden: true});
        Array.from(document.getElementsByTagName('audio')).forEach(el => {
            el.pause();
            el.currentTime = 0;
        });
        this.clearCookiesAndHistory();
    };

    clearCookiesAndHistory = () => {
        localStorage.clear();
        sessionStorage.clear();
        document.cookie.split(';').forEach(c => {
            document.cookie = c
                .replace(/^ +/, '')
                .replace(/[=].*/, `=;expires=${new Date().toUTCString()};path=/`);
        });
    };

    render() {
        return (
            <>
                <div className='button-show-modals'>
                    <ul className='menu width-100'>
                        <li>
                            <button className='expanded button' onClick={() => this.showModals()} type='button'>
                                <img src='/images/logo.svg'/>
                            </button>
                        </li>
                        <li>
                            <details>
                                <summary>
                                    <img alt={i18n.t('languages.current')} height={16} src={`/images/languages/${i18n.locale}.png`} width={16}/>
                                    &nbsp;
                                    {i18n.t('languages.current')}
                                </summary>
                                <ul className='sub-menu'>
                                    <li>
                                        <a hrefLang='en' onClick={() => this.changeLanguage('en')} rel='alternate'>
                                            <img alt={i18n.t('language.english')} height={16} src='/images/languages/en.png' width={16}/>
                                            &nbsp;
                                            <small>{translations.en.languages.english}</small>
                                        </a>
                                    </li>
                                    <li>
                                        <a hrefLang='es' onClick={() => this.changeLanguage('es')} rel='alternate'>
                                            <img alt={i18n.t('language.spanish')} height={16} src='/images/languages/es.png' width={16}/>
                                            &nbsp;
                                            <small>{translations.es.languages.spanish}</small>
                                        </a>
                                    </li>
                                    <li>
                                        <a hrefLang='fr' onClick={() => this.changeLanguage('fr')} rel='alternate'>
                                            <img alt={i18n.t('language.french')} height={16} src='/images/languages/fr.png' width={16}/>
                                            &nbsp;
                                            <small>{translations.fr.languages.french}</small>
                                        </a>
                                    </li>
                                </ul>
                            </details>
                        </li>
                    </ul>
                </div>
                <CallToActionModal
                    handleCallToActionClick={this.handleCallToActionClick}
                    hideModals={this.hideModals}
                    {...this.state}/>
                {this.props.children({hideModals: this.hideModals, state: this.state})}
            </>
        );
    }
}

ModalManager.propTypes = {
    children: PropTypes.func.isRequired
};