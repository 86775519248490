import React from 'react';
import PropTypes from 'prop-types';

const AudioContent = ({path}) =>
    <audio
        controls
        controlsList='nodownload'
        src={`/audio/${path}`}
        style={{width: '100%', maxWidth: '300px'}}
    />;

export default AudioContent;

AudioContent.propTypes = {path: PropTypes.string.isRequired};