import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {actionPlanDetailPath} from '../appContent/SDSContent';
import {emergencyTipsPath, otherConcernsPath} from '../Routes';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {Link} from 'react-router-dom';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

class ActionPlan extends Component {
    render() {
        const {myConcernResult} = this.props;
        let isMatch = false;
        let result = [];

        if (myConcernResult.length !== 0) {
            isMatch = myConcernResult[0].value === myConcernResult[1].value;
            result = myConcernResult.slice(0, isMatch ? 2 : 1);
        }

        return (
            <div className='grid-x align-center'>
                <div className='cell'>
                    <div className='grid-x action-plan-banner align-center-middle'>
                        <h3 className='separator-center' dangerouslySetInnerHTML={{__html: i18n.t('application.action_plan.sections.plan.header')}}/>
                    </div>
                </div>
                <div className='cell medium-9 small-10 text-center padding-vertical-2'>
                    <p dangerouslySetInnerHTML={{__html: i18n.t('application.action_plan.sections.plan.recommendation')}}/>
                    <div className='margin-top-2 margin-bottom-1'>
                        <ul
                            className='accordion small-11 medium-8 float-center'>
                            {
                                result.map(value =>
                                    <li
                                        className='accordion-item'
                                        key={value.name}
                                    >
                                        <Link className='accordion-title shadow right-arrow'
                                            to={{pathname: actionPlanDetailPath(value.name), state: {parent: 'action'}}}>
                                            <div className='grid-x align-middle margin-left-1' key={value.name}>
                                                <div className='shrink cell small-2 medium-1 text-center'>
                                                    <i className={`fa fa-${value.actionPlan.icon} 2x primary-color`}/>
                                                </div>
                                                <div className='shrink cell'>
                                                    <h6 className='my-concern-title'>{value.actionPlan.title}</h6>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>)
                            }
                            <li className='accordion-item'>
                                <Link to={emergencyTipsPath}>
                                    <div className='grid-x align-middle margin-left-1'>
                                        <div className='shrink cell small-2 medium-1 text-center'>
                                            <i className='fa fa-medkit 2x primary-color'/>
                                        </div>
                                        <div className='shrink cell'>
                                            <h6 className='my-concern-title first-letter-uppercase' dangerouslySetInnerHTML={{__html: i18n.t('application.action_plan.sections.plan.subheader')}}/>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='grid-x align-center-middle margin-top-3'>
                        <div className='cell large-5 small-10'>
                            <Link
                                className='expanded button primary shadow'
                                dangerouslySetInnerHTML={{__html: i18n.t('application.action_plan.sections.plan.continue_button')}}
                                to={otherConcernsPath}
                            />
                        </div>
                    </div>
                </div>
            </div>);
    }
}

const mapStateToProps = ({assessmentData}) => {
    const {myConcernResult} = assessmentData;
    return {myConcernResult};
};

export default connect(mapStateToProps)(ActionPlan);

ActionPlan.propTypes = {
    dispatch: PropTypes.func.isRequired,
    myConcernResult: PropTypes.arrayOf(PropTypes.shape()).isRequired
};