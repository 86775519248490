import React from 'react';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {emergencyTipsPath, hideYourTracksPath} from '../Routes';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const domesticURL =
    'https://www.ontario.ca/document/your-guide-employment-standards-act-0/' +
    'domestic-or-sexual-violence-leave';
const crimeLawURL =
    'https://www.cbabc.org/For-the-Public/Dial-A-Law/Scripts/Criminal-Law/206';
const petSafetyURL = 'https://www.ovma.org/pet-owners/safepet-program/';
const stalkedHelpURL = 'http://www.justice.gc.ca/eng/rp-pr/cj-jp/fv-vf/stalk-harc/har.html';
const stalkingLogURL = 'http://publications.gov.sk.ca/documents/9/95776-Stalking%20Log%2016-11-1.pdf';

export const pcawaSafetyURL = 'http://www.pcawa.net/safety-planning-guide.html';

export const mountsinaiSafetyURL = 'https://www.mountsinai.on.ca/about_us/health-equity/resources-to-address-violence-against-women/creating-a-safety-plan-english-2015-aoda/view';

export const safetyPlanSteps = [
    {
        id: 1,
        title: 'Children\'s Safety',
        label: i18n.t('application.safety_plan.steps.children')
    },
    {
        id: 2,
        title: 'Pet Safety',
        label: i18n.t('application.safety_plan.steps.pet')
    },
    {
        id: 3,
        title: 'Safety at Home',
        label: i18n.t('application.safety_plan.steps.home')
    },
    {
        id: 4,
        title: 'Safety at Work',
        label: i18n.t('application.safety_plan.steps.work')
    },
    {
        id: 5,
        title: 'Stalking and safety online',
        label: i18n.t('application.safety_plan.steps.online')
    }
];

const safetyPlanContent = () => [
    {
        title: i18n.t('application.safety_plan.sections.at_home.title'),
        description: '',
        linkedIds: [3],
        tabs: [
            {
                title: i18n.t('application.safety_plan.sections.at_home.subtitle'),
                description:
                    <div>
                        <p><b dangerouslySetInnerHTML={{__html: i18n.t('application.safety_plan.sections.at_home.header')}}/></p>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety_plan.sections.at_home.intro')}}/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety_plan.sections.at_home.actions.title')}}/>
                        <ul className='margin-bottom-0' dangerouslySetInnerHTML={{__html: i18n.t('application.safety_plan.sections.at_home.actions.items', {stayingSafeOnlinePath: hideYourTracksPath})}}/>
                        <ul><li dangerouslySetInnerHTML={{__html: i18n.t('application.safety_plan.sections.at_home.actions.emergency_escape', {stayingSafeOnlinePath: hideYourTracksPath, emergencyEscapePath: `${emergencyTipsPath}#tab-1`})}}/></ul>
                        <AudioFile path={`/${i18n.locale}/safety_plan_steps/safety_at_home_live_with_my_ex_partner.mp3`}/>
                    </div>

            },
            {
                title: i18n.t('application.safety_plan.sections.not_with_partner.title'),
                description:
                    <div>
                        <p><b dangerouslySetInnerHTML={{__html: i18n.t('application.safety_plan.sections.not_with_partner.header')}}/></p>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety_plan.sections.not_with_partner.actions.title')}}/>
                        <ul>
                            {i18n.t('application.safety_plan.sections.not_with_partner.actions.items').map((item, index) =>
                                <li key={index}><div dangerouslySetInnerHTML={{__html: item}}/></li>)}
                        </ul>
                        <AudioFile path={`/${i18n.locale}/safety_plan_steps/safety_at_home_you_dont_live_with_expartner.mp3`}/>
                    </div>

            }
        ]
    },
    {
        title: i18n.t('application.safety_plan.sections.child_plan.title'),
        linkedIds: [1],
        description:
            <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety_plan.sections.child_plan.body')}}/>
                <ul className='margin-bottom-0'>
                    {i18n.t('application.safety_plan.sections.child_plan.list_items').map((item, index) =>
                        <li key={index}><div dangerouslySetInnerHTML={{__html: item}}/></li>)}
                </ul>
                <p>
                    <AudioFile path={`/${i18n.locale}/safety_plan_steps/child_safety_plan_part_1.mp3`}/>
                </p>
                <ul className='margin-bottom-0'>
                    {i18n.t('application.safety_plan.sections.child_plan.list_items2').map((item, index) =>
                        <li key={index}><div dangerouslySetInnerHTML={{__html: item}}/></li>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/safety_plan_steps/child_safety_plan_part_2.mp3`}/>
            </div>
    },
    {
        title: i18n.t('application.safety_plan.sections.pets.title'),
        linkedIds: [2],
        description:
            <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety_plan.sections.pets.leaving_pets_behind', {petSafetyURL: petSafetyURL})}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety_plan.sections.pets.safe_pet_program')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety_plan.sections.pets.remember')}}/>
                <AudioFile path={`/${i18n.locale}/safety_plan_steps/pet_safety_plan.mp3`}/>
            </div>

    },
    {
        title: i18n.t('application.safety_plan.sections.work.title'),
        linkedIds: [4],
        description: '',
        tabs: [
            {
                title: i18n.t('application.safety_plan.sections.work.subtitle'),
                description:
                    <div>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety_plan.sections.work.covid_guidelines')}}/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety_plan.sections.work.tell_a_coworker')}}/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety_plan.sections.work.list_header')}}/>
                        <ul>
                            {i18n.t('application.safety_plan.sections.work.list_items').map((item, index) =>
                                <li key={index}><div dangerouslySetInnerHTML={{__html: item}}/></li>)}
                        </ul>
                        <AudioFile path={`/${i18n.locale}/safety_plan_steps/safety_at_work.mp3`}/>
                    </div>
            },
            {
                title: i18n.t('application.safety_plan.sections.violence.title'),
                description:
                    <div>
                        <ul>
                            {i18n.t('application.safety_plan.sections.violence.list_items').map((item, index) =>
                                <li key={index}><div dangerouslySetInnerHTML={{__html: item}}/></li>)}
                        </ul>
                        <AudioFile path={`/${i18n.locale}/safety_plan_steps/domestic_or_sexual_violence_leave.mp3`}/>
                    </div>
            },
            {
                title: i18n.t('application.safety_plan.sections.arriving_at_work.title'),
                description:
                    <div>
                        <ul>
                            {i18n.t('application.safety_plan.sections.arriving_at_work.list_items').map((item, index) =>
                                <li key={index}><div dangerouslySetInnerHTML={{__html: item}}/></li>)}
                        </ul>
                        <AudioFile path={`/${i18n.locale}/safety_plan_steps/when_arriving_or_leaving_work.mp3`}/>
                    </div>
            }
        ]
    },
    {
        title: i18n.t('application.safety_plan.sections.stalking.title'),
        linkedIds: [5],
        description:
            <div>
                <ul>
                    {i18n.t('application.safety_plan.sections.stalking.list_items').map((item, index) =>
                        <li key={index}><div dangerouslySetInnerHTML={{__html: item}}/><br/></li>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/safety_plan_steps/dealing_with_stalking.mp3`}/>
            </div>

    }
];

export default safetyPlanContent;