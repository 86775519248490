import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {quizPath} from '../Routes';
import {Link} from 'react-router-dom';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const Assessment = ({dispatch}) =>
    <div className='grid-x align-center'>
        <div className='cell grid-x assessment-banner align-center-middle'>
            <h3 className='separator-center' dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.opening_page.title')}}/>
        </div>
        <div className='cell medium-10 small-10 padding-vertical-2'>
            <div className='grid-x text-center align-center'>
                <div className='cell'>
                    <AudioFile path={`/${i18n.locale}/assessment/assess_prioritize_plan.mp3`}/>
                    <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.opening_page.paragraph1')}}/>
                </div>
                <div className='cell margin-top-1'>
                    <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.opening_page.paragraph2')}}/>
                </div>
                <div className='cell margin-top-1'>
                    <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.opening_page.paragraph3')}}/>
                </div>
                <div className='cell margin-top-1 medium-5'>
                    <Link
                        className='button expanded hollow-secondary shadow margin-0'
                        to={quizPath}
                    >
                        <div className='grid-x align-middle'>
                            <div className='cell auto' dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.opening_page.button', <i className='fa fa-arrow-right margin-left-1'/>)}}/>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>;

export default connect()(Assessment);

Assessment.propTypes = {
    dispatch: PropTypes.func.isRequired
};