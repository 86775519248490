import React from 'react';
import PropTypes from 'prop-types';
import AccordionContent from '../AccordionContent';
import HYTContent from '../appContent/HideYourTracks';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const HideYouTracks = ({location: {hash}}) =>
    <div className='grid-x align-center'>
        <div className='cell'>
            <div className='grid-x staying-safe-online-banner banner align-center-middle'>
                <h3 className='separator-center' dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.title')}}/>
            </div>
        </div>
        <div className='cell medium-9 small-10 padding-vertical-2' >
            <div className='margin-bottom-1'>
                <AudioFile path={`/${i18n.locale}/staying_safe_online/intro.mp3`}/>
            </div>
            {i18n.t('application.hide_your_tracks.paragraphs').map((item, index) =>
                <p dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
            <AccordionContent content={HYTContent} hash={hash}/>
        </div>
    </div>;

export default HideYouTracks;

HideYouTracks.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string
    }).isRequired
};