import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'i18n-js';
import translations from './../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

export default class CallToActionModal extends Component {
    render() {
        const {
            isHidden,
            isMainContentActive,
            hideModals,
            handleCallToActionClick
        } = this.props;

        return (
            <div className='call-to-action-modal modal active' hidden={isMainContentActive || isHidden}>
                <div className='modal-background' hidden={isHidden} onClick={() => hideModals()}/>
                <div
                    className='modal-content grid-x grid-margin-x'>
                    <div className='cell small large-6 content-wrapper grid-x'>
                        <div className='branding small-shrink'>
                            <img className='logo' src='/images/logo.svg'/>
                        </div>
                        <div className='branding-text small-auto'>
                            <h2 dangerouslySetInnerHTML={{__html: i18n.t('application.action.header')}}/>
                        </div>
                        <div className='information small-12'>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('application.action.paragraph')}}/>
                        </div>
                    </div>

                    <div className='cell medium large-6 button-container'>
                        <button
                            className='button expanded hollow-secondary shadow margin-0'
                            onClick={() => handleCallToActionClick()}
                            type='button'
                        >
                            <div className='grid-x align-middle'>
                                <div className='cell auto'>
                                    {i18n.t('application.action.button')}
                                    <i className='fa fa-arrow-right margin-left-1'/>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

CallToActionModal.propTypes = {
    handleCallToActionClick: PropTypes.func.isRequired,
    hideModals: PropTypes.func.isRequired,
    isHidden: PropTypes.bool.isRequired,
    isMainContentActive: PropTypes.bool.isRequired
};