import React from 'react';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {emergencyTipsPath} from '../Routes';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const dangerBottom = <div>
    <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.danger_bottom.header')}}/>
    <div className='grid-x'>
        <div className='cell'>
            <ul>
                {i18n.t('application.assessment.sections.results.danger_bottom.list_items').map((item, index) =>
                    <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
            </ul>
        </div>
    </div>
</div>;

const extremeDanger = {
    title: i18n.t('application.assessment.sections.results.extreme_danger.header'),
    topDescription:
        <div className='grid-x align-left text-left'>
            <div className='cell padding-bottom-1'>
                <div className='cell large-shrink small-12'>
                    <h1 className='text-center' dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.extreme_danger.header')}}/>
                </div>
                <div className='cell large-auto small-12'>
                    <AudioFile path={`/${i18n.locale}/assessment/results/extreme_danger.mp3`}/>
                </div>
            </div>
            <div className='cell'>
                {i18n.t('application.assessment.sections.results.extreme_danger.paragraphs').map((paragraph, index) =>
                    <p dangerouslySetInnerHTML={{__html: paragraph}} key={index}/>)}
            </div>
        </div>,
    bottomDescription:
        <div>
            {dangerBottom}
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.extreme_danger.bottom_description', {emergencyTipsPath: emergencyTipsPath})}}/>
        </div>
};

const severeDanger = {
    title: i18n.t('application.assessment.sections.results.severe_danger.header'),
    topDescription:
        <div className='grid-x align-left text-left'>
            <div className='cell padding-bottom-1'>
                <div className='cell large-shrink small-12'>
                    <h1 className='text-center' dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.severe_danger.header')}}/>
                </div>
                <div className='cell large-auto small-12'>
                    <AudioFile path={`/${i18n.locale}/assessment/results/severe_danger_prior_to_next_button.mp3`}/>
                </div>
            </div>
            <div className='cell'>
                <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.severe_danger.text.score_suggestion')}}/>
            </div>
        </div>,
    bottomDescription:
        <div>
            <AudioFile path={`/${i18n.locale}/assessment/results/severe_danger_under_next_button.mp3`}/>
            {dangerBottom}
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.severe_danger.text.bottom_description', {emergencyTipsPath: emergencyTipsPath})}}/>
        </div>
};

const increasedDanger = {
    title: 'Increased danger',
    topDescription:
        <div className='grid-x align-left text-left'>
            <div className='cell padding-bottom-1'>
                <div className='cell large-shrink small-12'>
                    <h1 className='text-center' dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.increased_danger.header')}}/>
                </div>
                <div className='cell large-auto small-12'>
                    <AudioFile path={`/${i18n.locale}/assessment/results/increased_danger_prior_to_next_button.mp3`}/>
                </div>
            </div>
            <div className='cell'>
                <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.increased_danger.text.score_suggestion')}}/>
            </div>
        </div>,
    bottomDescription: <div>
        <AudioFile path={`/${i18n.locale}/assessment/results/increased_danger_under_next_button.mp3`}/>
        <div className='grid-x'>
            <div className='cell'>
                <ul>
                    {i18n.t('application.assessment.sections.results.increased_danger.text.list_items').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>

                <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.increased_danger.text.bottom_description')}}/>
            </div>
        </div>
    </div>
};

const variableDanger = {
    title: 'Variable danger',
    topDescription:
        <div className='grid-x align-left text-left'>
            <div className='cell padding-bottom-1'>
                <div className='cell large-shrink small-12'>
                    <h1 className='text-center' dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.variable_danger.header')}}/>
                </div>
                <div className='cell large-auto small-12'>
                    <AudioFile path={`/${i18n.locale}/assessment/results/variable_danger_prior_to_next_button.mp3`}/>
                </div>
            </div>
            <div className='cell'>
                <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.variable_danger.text.score_suggestion')}}/>
            </div>
        </div>,
    bottomDescription: <div>
        <AudioFile path={`/${i18n.locale}/assessment/results/variable_danger_under_next_button.mp3`}/>
        <div className='grid-x'>
            <div className='cell'>
                <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.variable_danger.text.subheader')}}/>
                <ul>
                    {i18n.t('application.assessment.sections.results.variable_danger.text.list_items').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.variable_danger.text.bottom_description')}}/>
            </div>
        </div>
    </div>
};

export const extremeDangerScore = 18;

export const severeDangerScore = 14;

export const increasedDangerScore = 8;

export const assessmentResult = score => {
    if (score >= extremeDangerScore) {
        return extremeDanger;
    }
    else if (score >= severeDangerScore) {
        return severeDanger;
    }
    else if (score >= increasedDangerScore) {
        return increasedDanger;
    }

    return variableDanger;
};