import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InstructionsModal from '../components/InstructionsModal';
import {homePath} from './Routes';
import {I18n} from 'i18n-js';
import translations from './../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

export default class MainContentModal extends Component {
    render() {
        const {
            isHidden,
            hideModals,
            isMainContentActive
        } = this.props;

        // In development, we don't want to show the instruction modal and the introductory modal with app header
        const showMainContentInDev = process.env.RAILS_ENV === 'development' && ![
            '/',
            `/${i18n.locale}`,
            `/${i18n.locale}/`,
            `/${i18n.locale}/${homePath}`
        ].includes(location.pathname);

        return (
            <React.Fragment>
                <div className='main-content-modal modal' hidden={(!isMainContentActive || isHidden) && !showMainContentInDev}>
                    <div className='modal-background' onClick={() => hideModals()}/>
                    <div className='main-modal-content large-10 small-10'>
                        <div className='main-modal-content-container'>
                            {this.props.children}
                        </div>
                        <button
                            className='expanded button margin-0 exit'
                            onClick={() => hideModals()}
                            type='button'
                        >{i18n.t('application.layouts.exit_button')}</button>
                    </div>
                    <button
                        className='expanded button margin-0 exit small-10 outside-exit'
                        onClick={() => hideModals()}
                        type='button'
                    >{i18n.t('application.layouts.exit_button')}</button>
                    {!showMainContentInDev && <InstructionsModal hideModals={hideModals}/>}
                </div>
            </React.Fragment>
        );
    }
}

MainContentModal.propTypes = {
    children: PropTypes.element.isRequired,
    hideModals: PropTypes.func.isRequired,
    isHidden: PropTypes.bool.isRequired,
    isMainContentActive: PropTypes.bool.isRequired
};