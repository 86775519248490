import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import actionPlanContent from '../appContent/ActionPlan';
import {actionPlanDetailPath} from '../appContent/SDSContent';
import AccordionContent from '../AccordionContent';
import {actionPlanPath, otherConcernsPath} from '../Routes';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {Link} from 'react-router-dom';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

class ActionPlanDetail extends React.Component {
    render() {
        const {dispatch, isTop, match, location} = this.props;
        let parentRoute = actionPlanPath;
        let parentRouteName = i18n.t('application.back_button.action');

        if (location.state?.parent === 'action') {
            parentRoute = actionPlanPath;
            parentRouteName = i18n.t('application.back_button.action');
        }
        else if (location.state?.parent === 'safety') {
            parentRoute = otherConcernsPath;
            parentRouteName = i18n.t('application.back_button.other_concerns');
        }

        const pageContent = actionPlanContent(dispatch)[match.path];

        return (
            <div className='grid-x align-center'>
                <div className='cell'>
                    <div className='grid-x action-plan-banner align-center-middle'>
                        <h3 className='separator-center'>{pageContent.title}</h3>
                    </div>
                </div>
                <div className='cell medium-9 small-10 padding-vertical-2'>
                    {isTop && <p>{pageContent.topMyConcernText}</p>}
                    <div className='margin-bottom-2'>
                        {pageContent.topDescription}
                    </div>
                    {pageContent.tabs && <AccordionContent content={pageContent.tabs}/>}
                    {pageContent.bottomDescription}
                    <div className='grid-x align-center-middle margin-top-2'>
                        <div className='cell large-5 small-10'>
                            <Link
                                className='expanded button primary shadow'
                                to={parentRoute}
                            >{parentRouteName}</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({assessmentData: {myConcernResult}}, ownProps) => ({
    parent: ownProps.location.state ? ownProps.location.state.parent : '',
    isTop: myConcernResult.slice(0, 2).filter(value =>
        `/${i18n.locale}/${actionPlanDetailPath(value.name)}` === ownProps.match.path).length > 0
});

export default connect(mapStateToProps)(ActionPlanDetail);

ActionPlanDetail.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isTop: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    parent: PropTypes.string.isRequired
};