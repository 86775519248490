import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {assessmentPath, didYouKnowPath, hideYourTracksPath} from '../Routes';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {Link} from 'react-router-dom';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const Home = () =>
    <div className='grid-x'>
        <div className='cell hero'>
            <div className='hero-mobile-container'>
                <img className='hero-mobile' src='/images/woman-smile-hero.png'/>
                <div className='hero-gradient'/>
                <span className='logo-small margin-vertical-2 margin-horizontal-1'/>
            </div>
            <div className='hero-medium padding-3'>
                <div className='logo cell'>
                    <img className='padding-vertical-2' src='/images/logo-and-text-home.svg'/>
                </div>
                <div className='cell medium-7'>
                    <p className='font-bold'> {i18n.t('application.home.paragraph1')}</p>
                    <p className='font-bold'> {i18n.t('application.home.paragraph2')}</p>
                </div>
                <div className='grid-x large-8 grid-margin-x'>
                    <div className='cell grid-x align-middle medium-6 padding-top-2'>
                        <AudioFile path={`/${i18n.locale}/home/get_started.mp3`}/>
                    </div>
                    <div className='cell medium-6 padding-top-2'>
                        <Link className='button expanded hollow-secondary shadow margin-0' to={assessmentPath}>
                            <div className='grid-x align-middle'>
                                <div className='cell auto'>
                                    {i18n.t('application.home.assess_button')}
                                    <i className='fa fa-arrow-right margin-left-1'/>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='grid-x text-center large-up-2 grid-padding-x align-center align-top padding-bottom-2'>
            <div className='cell large-5 small-10 padding-top-2'>
                <div className='card'>
                    <div className='card-section padding-horizontal-2 padding-top-2'>
                        <h5 dangerouslySetInnerHTML={{__html: i18n.t('application.home.box1.box1header')}}/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('application.home.box1.box1paragraph')}}/>
                        <AudioFile path={`/${i18n.locale}/home/did_you_know.mp3`}/>
                    </div>
                    <Link to={didYouKnowPath}>
                        <div className='padding-bottom-1'>
                            <hr/>
                            {i18n.t('application.home.learn_more')}
                        </div>
                    </Link>
                </div>
            </div>
            <div className='cell large-5 small-10 padding-top-2'>
                <div className='card'>
                    <div className='card-section padding-horizontal-2 padding-top-2'>
                        <h5 dangerouslySetInnerHTML={{__html: i18n.t('application.home.box2.box2header')}}/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('application.home.box2.box2paragraph')}}/>
                        <AudioFile path={`/${i18n.locale}/home/staying_safe_online.mp3`}/>
                    </div>
                    <Link to={hideYourTracksPath}>
                        <div className='padding-bottom-1'>
                            <hr/>
                            {i18n.t('application.home.learn_more')}
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>;

export default connect()(Home);

Home.propTypes = {
    dispatch: PropTypes.func.isRequired
};